import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { TextField, Button, Container, Typography, Box, Alert, Paper, MenuItem, Select, FormControl, InputLabel, Autocomplete, Checkbox, FormControlLabel } from '@mui/material';
import { generateContract } from '../utils/docxUtils'; // Updated to use DOCX-based functions
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import { getAuth } from 'firebase/auth'; // Import for Firebase Auth

// Helper function to get Firebase Auth token
async function getAuthToken() {
  const auth = getAuth();
  return await auth.currentUser.getIdToken(); // Get the ID token for the current user
}

function LicenseeForm() {
  const [rentDate, setRentDate] = useState('');
  const [bedroom, setBedroom] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [deposit, setDeposit] = useState('');
  const [rent, setRent] = useState('');
  const [movedIn, setMovedIn] = useState('');
  const [movingOut, setMovingOut] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [eircode, setEircode] = useState('');
  const [address, setAddress] = useState('');
  const { currentUser } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // To handle loading state

  const [propertys, setPropertys] = useState([]); // List of unique property codes
  const [selectedProperty, setSelectedProperty] = useState(null); // Selected property code
  const [referenceCodes, setReferenceCodes] = useState([]); // Available reference codes for the property
  const [rentLocked, setRentLocked] = useState(true); // Whether rent is locked
  const [depositLocked, setDepositLocked] = useState(true); // Whether deposit is locked
  const [bedroomLocked, setBedroomLocked] = useState(true); // Whether bedroom is locked

  useEffect(() => {
    const fetchLicensees = async () => {
      try {
        const licenseeCollection = collection(db, 'licensees');
        const licenseeSnapshot = await getDocs(licenseeCollection);
        const licensees = licenseeSnapshot.docs.map(doc => doc.data());

        const propertyCodes = Array.from(new Set(licensees.map(licensee => {
          return licensee['REFERENCE CODE'].replace(/\d+$/, ''); // Remove trailing digits
        })));

        setPropertys(propertyCodes);
      } catch (err) {
        console.error('Failed to fetch licensees:', err);
      }
    };

    fetchLicensees();
  }, []);

  const handlePropertySelect = async (property) => {
    setSelectedProperty(property);

    const licenseeCollection = collection(db, 'licensees');
    const licenseeSnapshot = await getDocs(licenseeCollection);
    const licensees = licenseeSnapshot.docs.map(doc => doc.data());

    const propertyReferenceCodes = licensees
      .map(licensee => licensee['REFERENCE CODE'])
      .filter(code => code.startsWith(property))
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

    setReferenceCodes(propertyReferenceCodes);

    const matchingLicensee = licensees.find(licensee => licensee['REFERENCE CODE'].startsWith(property));
    if (matchingLicensee) {
      setRentDate(matchingLicensee['RENT DATE']);
      setAddress(matchingLicensee['ADDRESS']);
      setEircode(matchingLicensee['EIRCODE']);
    }

    setBedroom('');
  };

  const handleReferenceCodeSelect = async (reference) => {
    setReferenceCode(reference);

    const licenseeCollection = collection(db, 'licensees');
    const licenseeSnapshot = await getDocs(licenseeCollection);
    const licensees = licenseeSnapshot.docs.map(doc => doc.data());

    const selectedLicensee = licensees.find(licensee => licensee['REFERENCE CODE'] === reference);
    if (selectedLicensee) {
      setRent(selectedLicensee['RENT']);
      setDeposit(selectedLicensee['DEPOSIT']);
      setBedroom(selectedLicensee['BEDROOM']);
      setRentLocked(true);
      setDepositLocked(true);
      setBedroomLocked(true);
    }
  };

  const handleRentChange = () => {
    setRentLocked(!rentLocked);
  };

  const handleBedroomChange = () => {
    setBedroomLocked(!bedroomLocked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setError('You must be logged in to add a licensee');
      return;
    }

    const licenseeData = {
      'RENT DATE': rentDate,
      BEDROOM: bedroom,
      TYPE: type,
      NAME: name,
      SURNAME: surname,
      'REFERENCE CODE': referenceCode,
      DEPOSIT: deposit,
      RENT: rent,
      'MOVED IN': movedIn,
      'MOVING OUT': movingOut,
      EMAIL: email,
      'PHONE NUMBER': phoneNumber,
      EIRCODE: eircode,
      ADDRESS: address,
    };

    try {
      setError('');
      setMessage('');
      await addDoc(collection(db, 'licensees'), licenseeData);
      setMessage('Licensee added successfully');
    } catch (err) {
      console.error(err);
      setError('Failed to add licensee: ' + err.message);
    }
  };

  const handleGenerateContract = async () => {
    setMessage('Generating contract...');
    setError('');
    setLoading(true);

    try {
      const formData = {
        licenseeName: name + ' ' + surname,
        date: movedIn,
        propertyAddress: address,
        room: bedroom,
        referenceCode: referenceCode,
        bankDetails: 'IE86AIBK93101256223078', // Replace with actual bank details
        rentAmount: rent,
        depositAmount: deposit,
        startDate: movedIn,
        endDate: movingOut,
        term: type,
        emailAddress: email,
      };

      const docxBlob = await generateContract(formData);
      const storageRef = ref(storage, `contracts/${Date.now()}-contract.docx`);
      const uploadTask = uploadBytesResumable(storageRef, docxBlob, {
        contentType: 'docx',
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Error uploading DOCX:', error);
          setError('Failed to upload DOCX: ' + error.message);
          setMessage('');
          setLoading(false);
        },
        async () => {
          try {
            const docxUrl = await getDownloadURL(uploadTask.snapshot.ref);
            const token = await getAuthToken(); // Get Firebase Auth token

            const functionUrl = 'https://us-central1-lobi-portal.cloudfunctions.net/convertDocxToPdf';
            let conversionResponse;
            let attempts = 0;
            const maxAttempts = 5;

            while (attempts < maxAttempts) {
              try {
                conversionResponse = await axios.post(functionUrl, { docxUrl }, {
                  headers: { Authorization: `Bearer ${token}` }, // Include the token in the request
                });
                break;
              } catch (error) {
                if (error.response && error.response.status === 429) {
                  attempts += 1;
                  await new Promise(resolve => setTimeout(resolve, 1000 * attempts));
                } else {
                  throw error;
                }
              }
            }

            if (!conversionResponse) {
              throw new Error("Failed to convert DOCX to PDF after multiple attempts");
            }

            const pdfUrl = conversionResponse.data.pdfUrl;
            setMessage('Contract generated successfully! Click the link to download.');

            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'contract.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
          } catch (conversionError) {
            console.error('Error during PDF conversion:', conversionError);
            setError('Failed to convert DOCX to PDF: ' + conversionError.message);
            setMessage('');
            setLoading(false);
          }
        }
      );
    } catch (err) {
      console.error('Failed to generate contract:', err.message);
      setError('Failed to generate contract: ' + err.message);
      setMessage('');
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Add Licensee
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}

          <form onSubmit={handleSubmit}>
            {/* Property Dropdown */}
            <Autocomplete
              options={propertys}
              getOptionLabel={(option) => option}
              value={selectedProperty}
              onChange={(event, newValue) => handlePropertySelect(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Property" variant="outlined" margin="normal" fullWidth required />
              )}
            />
            <TextField
              fullWidth
              label="Eircode"
              value={eircode}
              onChange={(e) => setEircode(e.target.value)}
              required
              margin="normal"
              disabled
            />
            <TextField
              fullWidth
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              margin="normal"
            />
            {/* Reference Code Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Reference Code</InputLabel>
              <Select
                value={referenceCode}
                onChange={(e) => handleReferenceCodeSelect(e.target.value)}
                required
              >
                {referenceCodes.map((code, index) => (
                  <MenuItem key={index} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Rent Date Field */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Rent Date</InputLabel>
              <Select
                value={rentDate}
                onChange={(e) => setRentDate(e.target.value)}
                required
                disabled
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="15">15</MenuItem>
              </Select>
            </FormControl>

            {/* Bedroom Field */}
            <FormControl fullWidth margin="normal">
              <TextField
                fullWidth
                label="Bedroom"
                value={bedroom}
                onChange={(e) => setBedroom(e.target.value)}
                required
                disabled={bedroomLocked}
                margin="normal"
              />
              <FormControlLabel
                control={<Checkbox checked={!bedroomLocked} onChange={handleBedroomChange} />}
                label="Change Bedroom"
              />
            </FormControl>

            {/* Type Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Double">Double</MenuItem>
                <MenuItem value="Twin">Twin</MenuItem>
              </Select>
            </FormControl>

            {/* Name Fields */}
            <TextField
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              label="Surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              required
              margin="normal"
            />

            {/* Rent and Deposit Fields */}
            <FormControl fullWidth margin="normal">
              <TextField
                fullWidth
                label="Rent"
                type="number"
                value={rent}
                onChange={(e) => setRent(e.target.value)}
                disabled={rentLocked}
                margin="normal"
              />
              <FormControlLabel
                control={<Checkbox checked={!rentLocked} onChange={handleRentChange} />}
                label="Change Rent"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                fullWidth
                label="Deposit"
                type="number"
                value={deposit}
                onChange={(e) => setDeposit(e.target.value)}
                disabled={depositLocked}
                margin="normal"
              />
              <FormControlLabel
                control={<Checkbox checked={!depositLocked} onChange={() => setDepositLocked(!depositLocked)} />}
                label="Change Deposit"
              />
            </FormControl>

            <TextField
              fullWidth
              label="Moved In"
              type="date"
              value={movedIn}
              onChange={(e) => setMovedIn(e.target.value)}
              required
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              label="Moving Out"
              type="date"
              value={movingOut}
              onChange={(e) => setMovingOut(e.target.value)}
              required
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              margin="normal"
            />

            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Add Licensee
            </Button>
          </form>
          {message && <Alert severity={message.includes('Generating') ? 'info' : 'success'}>{message}</Alert>}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGenerateContract}
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            Generate Contract PDF
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default LicenseeForm;
