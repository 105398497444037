import React from 'react';
import NavBar from './NavBar';
import { Container, Box } from '@mui/material';

const Layout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <NavBar />
      <Container sx={{ flexGrow: 1, mt: 2 }}>
        {children}
      </Container>
    </Box>
  );
};

export default Layout;
