// RegistrationSuccess.js
import React from 'react';
import { Container, Typography, Box, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function RegistrationSuccess() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigate to homepage or login page
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Registration Successful
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Your registration was successful! An admin will now verify your account details and activate your account.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleGoHome}>
            Go to Homepage
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default RegistrationSuccess;
