import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Paper, Alert, TextField, MenuItem, Select, FormControl, InputLabel, Autocomplete } from '@mui/material';
import { generateContract, generateProofOfAddress, convertDocxToPdf } from '../utils/docxUtils';
import { collection, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { getAuth } from 'firebase/auth'; // Import for Firebase Auth

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

function capitalizeWords(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function capitalizeName(name, surname) {
  return `${capitalizeWords(name)} ${capitalizeWords(surname)}`;
}

// Helper function to get Firebase Auth token
async function getAuthToken() {
  const auth = getAuth();
  return await auth.currentUser.getIdToken(); // Get the ID token for the current user
}

function GenerateDocuments() {
  const { userRole } = useAuth();
  const [formData, setFormData] = useState({
    licenseeName: '',
    propertyAddress: '',
    room: '',
    referenceCode: '',
    bankDetails: '',
    rentAmount: '',
    depositAmount: '',
    startDate: '',
    endDate: '',
    emailAddress: '',
    passportNumber: '',  
    term: '6 MONTHS',
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [propertys, setPropertys] = useState([]);
  const [referenceCodes, setReferenceCodes] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [referenceCode, setReferenceCode] = useState('');

  useEffect(() => {
    const fetchLicensees = async () => {
      try {
        const licenseeCollection = collection(db, 'licensees');
        const licenseeSnapshot = await getDocs(licenseeCollection);
        const licensees = licenseeSnapshot.docs.map(doc => doc.data());

        const propertyCodes = Array.from(new Set(licensees.map(licensee => {
          return licensee['REFERENCE CODE'].replace(/\d+$/, ''); // Remove trailing digits from reference codes
        })));

        setPropertys(propertyCodes);
      } catch (err) {
        console.error('Failed to fetch licensees:', err);
      }
    };

    fetchLicensees();
  }, []);

  const handlePropertySelect = async (property) => {
    setSelectedProperty(property);

    const licenseeCollection = collection(db, 'licensees');
    const licenseeSnapshot = await getDocs(licenseeCollection);
    const licensees = licenseeSnapshot.docs.map(doc => doc.data());

    const propertyReferenceCodes = licensees
      .map(licensee => licensee['REFERENCE CODE'])
      .filter(code => code.startsWith(property));

    setReferenceCodes(propertyReferenceCodes);
  };

  const handleReferenceCodeSelect = async (reference) => {
    setReferenceCode(reference);

    const licenseeCollection = collection(db, 'licensees');
    const licenseeSnapshot = await getDocs(licenseeCollection);
    const licensees = licenseeSnapshot.docs.map(doc => doc.data());

    const selectedLicensee = licensees.find(licensee => licensee['REFERENCE CODE'] === reference);
    if (selectedLicensee) {
      setFormData({
        ...formData,
        licenseeName: capitalizeName(selectedLicensee.NAME, selectedLicensee.SURNAME),
        propertyAddress: capitalizeWords(selectedLicensee.ADDRESS),
        room: selectedLicensee.BEDROOM,
        referenceCode: selectedLicensee['REFERENCE CODE'],
        rentAmount: selectedLicensee.RENT,
        depositAmount: selectedLicensee.DEPOSIT,
        startDate: formatDate(selectedLicensee['MOVED IN']),
        endDate: formatDate(selectedLicensee['MOVING OUT']),
        emailAddress: selectedLicensee.EMAIL,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleGenerateContract = async () => {
    setMessage('Generating, please wait...');
    setError('');

    try {
      const docxBlob = await generateContract(formData);
      const storageRef = ref(storage, `contracts/${Date.now()}-contract.docx`);
      const uploadTask = uploadBytesResumable(storageRef, docxBlob, {
        contentType: 'docx',
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Error uploading DOCX:', error);
          setError('Failed to upload DOCX: ' + error.message);
          setMessage('');
        },
        async () => {
          try {
            const docxUrl = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("Generated docxUrl:", docxUrl);

            const token = await getAuthToken(); // Get the Firebase token

            const functionUrl = 'https://us-central1-lobi-portal.cloudfunctions.net/convertDocxToPdf';
            let conversionResponse;
            let attempts = 0;
            const maxAttempts = 5;

            while (attempts < maxAttempts) {
              try {
                conversionResponse = await axios.post(functionUrl, { docxUrl }, {
                  headers: { Authorization: `Bearer ${token}` }, // Include the token in the request
                });
                break;
              } catch (error) {
                if (error.response && error.response.status === 429) {
                  attempts += 1;
                  await new Promise(resolve => setTimeout(resolve, 1000 * attempts));
                } else {
                  throw error;
                }
              }
            }

            if (!conversionResponse) {
              throw new Error("Failed to convert DOCX to PDF after multiple attempts");
            }

            const pdfUrl = conversionResponse.data.pdfUrl;
            console.log("Generated pdfUrl:", pdfUrl);
            setMessage('Contract generated successfully! Download should begin shortly.');

            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'contract.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (conversionError) {
            console.error('Error during PDF conversion:', conversionError);
            setError('Failed to convert DOCX to PDF: ' + conversionError.message);
            setMessage('');
          }
        }
      );
    } catch (err) {
      console.error('Failed to generate contract:', err.message);
      setError('Failed to generate contract: ' + err.message);
      setMessage('');
    }
  };

  const handleGenerateProofOfAddress = async () => {
    setMessage('Generating, please wait...');
    setError('');

    try {
      const docxBlob = await generateProofOfAddress(formData);
      const storageRef = ref(storage, `proofs/${Date.now()}-proof_of_address.docx`);
      const uploadTask = uploadBytesResumable(storageRef, docxBlob, {
        contentType: 'docx',
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Error uploading DOCX:', error);
          setError('Failed to upload DOCX: ' + error.message);
          setMessage('');
        },
        async () => {
          try {
            const docxUrl = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("Generated docxUrl:", docxUrl);

            const token = await getAuthToken(); // Get the Firebase token

            const functionUrl = 'https://us-central1-lobi-portal.cloudfunctions.net/convertDocxToPdf';
            let conversionResponse;
            let attempts = 0;
            const maxAttempts = 5;

            while (attempts < maxAttempts) {
              try {
                conversionResponse = await axios.post(functionUrl, { docxUrl }, {
                  headers: { Authorization: `Bearer ${token}` }, // Include the token in the request
                });
                break;
              } catch (error) {
                if (error.response && error.response.status === 429) {
                  attempts += 1;
                  await new Promise(resolve => setTimeout(resolve, 1000 * attempts
                    ));
                  } else {
                    throw error;
                  }
                }
              }
  
              if (!conversionResponse) {
                throw new Error("Failed to convert DOCX to PDF after multiple attempts");
              }
  
              const pdfUrl = conversionResponse.data.pdfUrl;
              console.log("Generated pdfUrl:", pdfUrl);
              setMessage('Proof of Address generated successfully! Click the link to download.');
  
              const link = document.createElement('a');
              link.href = pdfUrl;
              link.setAttribute('download', 'proof_of_address.pdf');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } catch (conversionError) {
              console.error('Error during PDF conversion:', conversionError);
              setError('Failed to convert DOCX to PDF: ' + conversionError.message);
              setMessage('');
            }
          }
        );
      } catch (err) {
        console.error('Failed to generate Proof of Address:', err.message);
        setError('Failed to generate Proof of Address: ' + err.message);
        setMessage('');
      }
    };
  
    if (userRole !== 'viewings' && userRole !== 'admin') {
      return <Alert severity="error">You do not have permission to access this page.</Alert>;
    }
  
    return (
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
          <Typography variant="h4" gutterBottom>
            Generate Documents
          </Typography>
          <Typography variant="body1" gutterBottom>
            Select the property and reference code to auto-fill the form.
          </Typography>
  
          <Box sx={{ mt: 3 }}>
            <Autocomplete
              options={propertys}
              getOptionLabel={(option) => option}
              value={selectedProperty}
              onChange={(event, newValue) => handlePropertySelect(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Property" variant="outlined" margin="normal" fullWidth required />
              )}
            />
  
            <FormControl fullWidth margin="normal">
              <InputLabel>Reference Code</InputLabel>
              <Select
                value={referenceCode}
                onChange={(e) => handleReferenceCodeSelect(e.target.value)}
                required
              >
                {referenceCodes.map((code, index) => (
                  <MenuItem key={index} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
  
            <TextField
              label="Licensee Name"
              name="licenseeName"
              value={formData.licenseeName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Property Address"
              name="propertyAddress"
              value={formData.propertyAddress}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Room"
              name="room"
              value={formData.room}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Rent Amount"
              name="rentAmount"
              value={formData.rentAmount}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Deposit Amount"
              name="depositAmount"
              value={formData.depositAmount}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Start Date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="End Date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email Address"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
  
            {/* New Passport Number field */}
            <TextField
              label="Passport Number (Optional)"
              name="passportNumber"
              value={formData.passportNumber}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Box>
  
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateProofOfAddress}
              sx={{ minWidth: 200, m: 1 }}
            >
              Generate Proof of Address
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGenerateContract}
              sx={{ minWidth: 200, m: 1 }}
            >
              Generate Contract
            </Button>
          </Box>
  
          {/* Error and Success messages */}
          <Box sx={{ mt: 2 }}>
            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}
          </Box>
        </Paper>
      </Container>
    );
  }
  
  export default GenerateDocuments;
  