import React, { useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tab } from '@mui/material';
import * as XLSX from 'xlsx';

const TrackingProfit = () => {
  const [data, setData] = useState({});
  const [selectedTab, setSelectedTab] = useState('SUMMARY - tracking Profit');
  const sheetNames = ['SUMMARY - tracking Profit', 'NO PROFIT AFTER STAFF COSTS', 'BOTTOM 10 LOWEST', 'LESS PROFIT THAN STAFF COSTS', 'BASE 1ST', 'BASE 15TH', 'COVID RENT CHECK', 'COVID STILL DOWN', 'COLINS PLACES DEAL'];

  // Function to process the Excel file and parse the data
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const newData = {};
      sheetNames.forEach((sheet) => {
        const worksheet = workbook.Sheets[sheet];
        newData[sheet] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      });
      setData(newData);
    };

    reader.readAsBinaryString(file);
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Supplier Profit Tracking</Typography>
      <input type="file" onChange={handleFileUpload} accept=".xlsx, .xls" />
      
      {Object.keys(data).length > 0 && (
        <>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {sheetNames.map((name) => (
              <Tab label={name} value={name} key={name} />
            ))}
          </Tabs>

          {data[selectedTab] && (
            <Table>
              <TableHead>
                <TableRow>
                  {data[selectedTab][0]?.map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data[selectedTab].slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </Box>
  );
};

export default TrackingProfit;
