import React, { useContext, useState, useEffect, createContext } from 'react';
import { 
  onAuthStateChanged, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut, 
  sendPasswordResetEmail 
} from 'firebase/auth';
import { db } from '../firebase'; 
import { doc, getDoc } from 'firebase/firestore'; // Firestore functions
import { auth } from '../firebase'; // Import auth from firebase.js

// Create the Auth context
const AuthContext = createContext();

// Custom hook to use the Auth context
export function useAuth() {
  return useContext(AuthContext);
}

// AuthProvider component that wraps your app
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null); // Track user role (e.g., 'admin', 'user')
  const [userStatus, setUserStatus] = useState(null); // Track user's approval status
  const [loading, setLoading] = useState(true); // Track loading state

  // Fetch user role and status from Firestore
  const fetchUserRoleAndStatus = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserRole(userData.role); // Set user role from Firestore
        setUserStatus(userData.status); // Set user status (e.g., 'pending', 'approved')

        return userData; // Return user data so it can be used immediately
      } else {
        console.error('User document does not exist.');
        throw new Error('No user data found.');
      }
    } catch (error) {
      console.error('Error fetching user role and status:', error);
      signOut(auth); // Sign out the user if there's an issue fetching the document
      setCurrentUser(null);
      setUserRole(null);
      setUserStatus(null);
      throw error; // Re-throw error so it can be caught in login function
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // If a user is authenticated, fetch their role and status
        setCurrentUser(user);
        try {
          await fetchUserRoleAndStatus(user);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        // If no user is authenticated, reset the context
        setCurrentUser(null);
        setUserRole(null);
        setUserStatus(null);
      }
      setLoading(false); // Stop loading once the user status is resolved
    });
  
    return unsubscribe; // Clean up the listener on unmount
  }, []);
  
  // Function to handle signup
  const signup = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user; // Return the user object
  };

  // Function to handle login
  const login = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Fetch user document from Firestore after login
    const userData = await fetchUserRoleAndStatus(user); // Fetch user data directly from Firestore

    // Check if the user status is pending or approved
    if (userData.status !== 'approved') {
      throw new Error('Your account is pending approval. Please wait for admin approval.');
    }

    return userCredential;
  };

  // Function to handle password reset
  const resetPassword = async (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  // Function to handle logout
  const logout = () => {
    return signOut(auth);
  };

  // Auth context value passed to the components
  const value = {
    currentUser,
    userRole,
    userStatus, // Add user status to the context
    setUserStatus, // Pass down the function to set user status
    signup,
    login,
    logout,
    resetPassword, // Pass down reset password functionality
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Render children only when not loading */}
    </AuthContext.Provider>
  );
}
