import { Link } from 'react-router-dom';
import './Footer.css'; // Ensure you import your CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
      </div>
    </footer>
  );
};

export default Footer;
