import React, { useState } from 'react';
import { Container, TextField, Button, Box, Typography, LinearProgress } from '@mui/material';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure Firebase is properly initialized

const PrivacyPolicy = () => {
    const [formData, setFormData] = useState({ email: '', requestType: 'access', message: '' });
    const [submitted, setSubmitted] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('idle'); // To manage form submission state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploadStatus('submitting');

        console.log("Form submitted with data:", formData);

        try {
            // Log Firebase operation start
            console.log("Attempting to store GDPR request in Firestore...");

            // Store GDPR request data in Firestore
            const docRef = await addDoc(collection(db, 'gdpr-requests'), {
                email: formData.email,
                requestType: formData.requestType,
                message: formData.message,
                status: 'Pending',
                createdAt: new Date(),
            });

            // Log successful document creation
            console.log("GDPR request stored successfully with ID:", docRef.id);

            setSubmitted(true);
            setUploadStatus('success');
        } catch (error) {
            // Log any errors
            console.error('Error submitting GDPR request:', error);
            setUploadStatus('error');
        }
    };

    return (
        <Container maxWidth="md">
            <div className="privacy-policy-container">
                <Typography variant="h4" align="center" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography>
                    At Linehan O'Brien Investments, we value your privacy and are committed to protecting your personal data in compliance with GDPR and Irish data protection laws. This privacy policy outlines how we collect, use, retain, and protect your personal information.
                </Typography>

                <h2>1. Introduction</h2>
                <Typography>This privacy policy outlines how we collect, use, retain, and protect your personal information.</Typography>

                <h2>2. Data We Collect</h2>
                <Typography>We may collect the following personal data:</Typography>
                <ul>
                    <li><strong>Personal Identification Information:</strong> Name, address, email, phone number.</li>
                    <li><strong>Financial Information:</strong> Payment details, rent payments, transaction history.</li>
                    <li><strong>Tenancy Information:</strong> Lease agreements, move-in/move-out dates, communication records.</li>
                    <li><strong>Usage Data:</strong> IP address, browser type, browsing behavior.</li>
                    <li><strong>Cookies and Tracking Data:</strong> We use cookies to track activity on our website and store certain information, such as preferences and login sessions.</li>
                </ul>

                <h2>3. How We Use Your Data</h2>
                <Typography>We process personal data for the following purposes:</Typography>
                <ul>
                    <li>To manage tenancy agreements and fulfill our contractual obligations.</li>
                    <li>To comply with legal requirements, such as retaining tenant records for law enforcement purposes.</li>
                    <li>To respond to queries and manage our ongoing service relationship.</li>
                </ul>

                <h2>4. Legal Basis for Data Processing</h2>
                <Typography>We process personal data based on the following legal grounds:</Typography>
                <ul>
                    <li><strong>Performance of a Contract:</strong> Managing your tenancy agreement.</li>
                    <li><strong>Legal Obligation:</strong> Retaining data in compliance with Irish laws, such as the Criminal Justice (Money Laundering and Terrorist Financing) Act 2010.</li>
                    <li><strong>Consent:</strong> For optional services like email communications or marketing (only with your explicit consent).</li>
                </ul>

                <h2>5. Data Retention</h2>
                <Typography>We retain personal data for as long as necessary:</Typography>
                <ul>
                    <li>Tenancy records: kept for <strong>six years</strong> after the end of the tenancy for legal and auditing purposes.</li>
                    <li>Financial and anti-money laundering records: retained for at least <strong>five years</strong> post-tenancy.</li>
                    <li>In some cases, data may be retained for up to <strong>13 years</strong> due to legal obligations.</li>
                </ul>

                <h2>6. Your GDPR Rights</h2>
                <Typography>Under the GDPR, you have the following rights:</Typography>
                <ul>
                    <li><strong>Right to Access:</strong> You can request access to the data we hold about you.</li>
                    <li><strong>Right to Rectification:</strong> You can correct inaccurate or incomplete data.</li>
                    <li><strong>Right to Erasure:</strong> You can request deletion of your data unless legal obligations require its retention.</li>
                    <li><strong>Right to Object:</strong> You can object to the processing of your data for specific purposes.</li>
                    <li><strong>Right to Data Portability:</strong> You can request that your personal data be transferred to another service provider.</li>
                    <li><strong>Right to Withdraw Consent:</strong> You can withdraw your consent at any time for processing that relies on consent.</li>
                </ul>

                <h2>7. Handling Data Deletion Requests</h2>
                <Typography>While we respect your right to request deletion of your data, we may retain some data to comply with legal obligations. For example, tenancy records must be retained for a specified period even after a tenant has left the property.</Typography>

                <h2>8. How We Protect Your Data</h2>
                <Typography>We implement a variety of security measures to protect your data, including:</Typography>
                <ul>
                    <li><strong>Encryption:</strong> Sensitive data is encrypted both in transit and at rest.</li>
                    <li><strong>Access Controls:</strong> Only authorized personnel have access to your personal data.</li>
                    <li><strong>Regular Security Audits:</strong> We regularly review and update our security measures.</li>
                    <li><strong>Data Minimization:</strong> We only collect and store the data necessary for our services.</li>
                </ul>

                <h2>9. Cookies and Tracking Technologies</h2>
                <Typography>We use cookies and similar technologies to enhance your browsing experience and for analytics. You can manage your cookie preferences via your browser or a cookie management tool on our site.</Typography>

                <h2>10. Data Breach Policy</h2>
                <Typography>In the unlikely event of a data breach, we will notify the relevant authorities and affected individuals within 72 hours, as required by law.</Typography>

                <h2>11. Third-Party Data Sharing</h2>
                <Typography>We share your data with third parties only for necessary services such as payment processing and tenancy management. All third-party services we engage with are GDPR-compliant.</Typography>

                <h2>Contact Us</h2>
                <Typography>If you wish to access, correct, or delete your data, please use the form below.</Typography>

                {submitted ? (
                    <div className="thank-you-message">
                        <Typography variant="h6" align="center" color="success.main">
                            Thank you! Your request has been submitted. We will respond shortly.
                        </Typography>
                    </div>
                ) : (
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Request Type"
                            name="requestType"
                            value={formData.requestType}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            fullWidth
                            required
                            sx={{ mt: 2 }}
                        >
                            <option value="access">Access My Data</option>
                            <option value="rectification">Correct My Data</option>
                            <option value="deletion">Delete My Data</option>
                        </TextField>
                        <TextField
                            label="Additional Message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 3 }}
                            disabled={uploadStatus === 'submitting'}
                        >
                            Submit Request
                        </Button>

                        {uploadStatus === 'submitting' && (
                            <Box sx={{ mt: 2 }}>
                                <LinearProgress />
                                <Typography align="center" sx={{ mt: 1 }}>
                                    Submitting your request...
                                </Typography>
                            </Box>
                        )}

                        {uploadStatus === 'error' && (
                            <Typography align="center" color="error" sx={{ mt: 2 }}>
                                There was an error submitting your request. Please try again later.
                            </Typography>
                        )}
                    </Box>
                )}
            </div>
        </Container>
    );
};

export default PrivacyPolicy;
