import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';

function SuccessPage() {
    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
                <Typography variant="h1" gutterBottom>
                    Submission Successful!
                </Typography>
                <Typography variant="body1" gutterBottom>
                    One of our staff members will contact you shortly. Thank you for submitting your notice.
                </Typography>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                        You may now close this page or return to the dashboard.
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
}

export default SuccessPage;
