import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Select, MenuItem, FormControl, Container,
  CircularProgress, TextField, Box, Button, Tooltip, TableSortLabel
} from '@mui/material';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import './Dashboard.css';

function Dashboard() {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatingIssueId, setUpdatingIssueId] = useState(null);
  const [trackingIssueId, setTrackingIssueId] = useState(null); // To track the issue that is currently being tracked
  const [search, setSearch] = useState('');
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  const [groupBy, setGroupBy] = useState('property');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchIssues = async () => {
      const issuesSnapshot = await getDocs(collection(db, 'maintenance'));
      const issuesList = issuesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      const issuesWithProperty = issuesList.map(issue => ({
        ...issue,
        property: issue.property || issue.house || 'Unknown'
      }));

      setIssues(issuesWithProperty);
      setLoading(false);
    };

    fetchIssues();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 300);

  useEffect(() => {
    setFilteredIssues(
      issues.filter(issue => 
        (issue.property ? issue.property.toLowerCase() : '').includes(search.toLowerCase()) ||
        (issue.issueType ? issue.issueType.toLowerCase() : '').includes(search.toLowerCase()) ||
        (issue.description ? issue.description.toLowerCase() : '').includes(search.toLowerCase()) ||
        (issue.location ? issue.location.toLowerCase() : '').includes(search.toLowerCase()) ||
        (issue.status ? issue.status.toLowerCase() : '').includes(search.toLowerCase())
      )
    );
  }, [search, issues]);

  const handleUpdateStatus = async (issueId, status) => {
    setUpdatingIssueId(issueId);

    const updatedIssues = issues.map(issue => 
      issue.id === issueId ? { ...issue, status } : issue
    );
    setIssues(updatedIssues);

    const issueRef = doc(db, 'maintenance', issueId);
    await updateDoc(issueRef, { status })
      .catch(error => {
        console.error('Error updating status:', error);
        setIssues(prevIssues =>
          prevIssues.map(issue => issue.id === issueId ? { ...issue, status: 'Reported' } : issue)
        );
      })
      .finally(() => {
        setUpdatingIssueId(null);
      });
  };

  const handleTracker = async (issueId) => {
    if (trackingIssueId === issueId) {
      // Stop tracking
      setTrackingIssueId(null);
      console.log(`Tracking stopped for issue: ${issueId}`);
      return;
    }
  
    setTrackingIssueId(issueId); // Start tracking
  
    if ("geolocation" in navigator) {
      navigator.geolocation.watchPosition(async (position) => {
        const { latitude, longitude } = position.coords;
  
        const issueRef = doc(db, 'maintenance', issueId);
        
        // Update or create the `locationTracking` field in Firestore
        await updateDoc(issueRef, {
          locationTracking: {
            lat: latitude,
            lng: longitude,
            lastUpdated: new Date(),
          },
        });
  
        console.log('Location updated:', { latitude, longitude });
      }, (error) => {
        console.error("Geolocation error: ", error);
      });
    } else {
      alert('Geolocation is not available on this browser.');
    }
  };
  

  const generateTrackingLink = (issueId) => {
    return `/track/${issueId}`; // This would point to a tracking page that shows the location
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedIssues = [...filteredIssues].sort((a, b) => {
    const aValue = a[sortConfig.key] ? a[sortConfig.key] : '';
    const bValue = b[sortConfig.key] ? b[sortConfig.key] : '';

    if (sortConfig.direction === 'asc') {
      return aValue > bValue ? 1 : -1;
    }
    return aValue < bValue ? 1 : -1;
  });

  const groupIssues = (issues) => {
    return issues.reduce((group, issue) => {
      const key = groupBy === 'property' ? issue.property || 'Unknown' : issue.status || 'Reported';
      if (!group[key]) {
        group[key] = [];
      }
      group[key].push(issue);
      return group;
    }, {});
  };

  const sortByStatus = (issues) => {
    const statusOrder = { 'Reported': 1, 'In Progress': 2, 'Resolved': 3 };
    return issues.sort((a, b) => {
      const aStatus = statusOrder[a.status] || 4;
      const bStatus = statusOrder[b.status] || 4;
      return aStatus - bStatus;
    });
  };

  const groupedIssues = groupIssues(sortedIssues);

  if (loading) {
    return <Typography variant="h6">Loading data...</Typography>;
  }

  const toggleCardContent = (group, index) => {
    setIssues(issues.map((issue, i) => (
      issue.id === groupedIssues[group][index].id ? { ...issue, isOpen: !issue.isOpen } : issue
    )));
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Maintenance Issues Dashboard
      </Typography>

      <Link to="/video-management" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="primary" sx={{ mb: 3 }}>
          Video / Photo Management
        </Button>
      </Link>

      <TextField
        data-testid="search-input"
        label="Search Issues"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search by property, issue type, description, or status"
      />

      <FormControl fullWidth margin="normal">
        <Select
          data-testid="group-by-select" 
          value={groupBy}
          onChange={(e) => setGroupBy(e.target.value)}
          fullWidth
        >
          <MenuItem value="property">Group by Property</MenuItem>
          <MenuItem value="status">Group by Status</MenuItem>
        </Select>
      </FormControl>

      {Object.keys(groupedIssues).map((group) => (
        <Box key={group} mt={4}>
          <Typography variant="h5" gutterBottom>
            {group}
          </Typography>

          {!isMobile && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {groupBy === 'status' && <TableCell>Property</TableCell>} 
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'createdAt'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('createdAt')}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'issueType'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('issueType')}
                      >
                        Issue Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Media</TableCell> 
                    {groupBy === 'property' && <TableCell>Status</TableCell>} 
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortByStatus(groupedIssues[group]).map((issue) => (
                    <TableRow key={issue.id}>
                      {groupBy === 'status' && <TableCell>{issue.property}</TableCell>} 
                      <TableCell>{issue.createdAt ? format(issue.createdAt.toDate(), 'yyyy-MM-dd') : 'No Date'}</TableCell>
                      <TableCell>{issue.issueType}</TableCell>
                      <TableCell>
                        <Tooltip title={issue.description}>
                          <span style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                            maxWidth: '150px'
                          }}>
                            {issue.description}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{issue.location}</TableCell>

                      {/* Media Preview/Download */}
                      <TableCell>
                        {issue.fileUrl ? (
                          <>
                            {issue.fileUrl.includes('.mp4') || issue.fileUrl.includes('.webm') ? (
                              <video width="150" controls>
                                <source src={issue.fileUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img src={issue.fileUrl} alt="Uploaded media" width="150" />
                            )}
                            <Box mt={1}>
                              <Button variant="outlined" href={issue.fileUrl} target="_blank" download>
                                Download
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <Typography>No media</Typography>
                        )}
                      </TableCell>

                      {groupBy === 'property' && (
                        <TableCell>
                          <FormControl fullWidth variant="outlined" size="small">
                            {updatingIssueId === issue.id ? (
                              <CircularProgress size={24} />
                            ) : (
                              <Select
                                value={issue.status || 'Reported'}
                                onChange={(e) => handleUpdateStatus(issue.id, e.target.value)}
                              >
                                <MenuItem value="Reported">Reported</MenuItem>
                                <MenuItem value="In Progress">In Progress</MenuItem>
                                <MenuItem value="Resolved">Resolved</MenuItem>
                              </Select>
                            )}
                          </FormControl>
                        </TableCell>
                      )}
                      <TableCell>
                        <Button
                          variant="contained"
                          color={trackingIssueId === issue.id ? "secondary" : "primary"}
                          onClick={() => handleTracker(issue.id)}
                        >
                          {trackingIssueId === issue.id ? "TRACKING" : "TRACKER"}
                        </Button>

                        {/* Link to view tracking page */}
                        {trackingIssueId === issue.id && (
                          <Link to={generateTrackingLink(issue.id)} target="_blank">
                            <Button variant="contained" color="info" sx={{ ml: 2 }}>
                              View Map
                            </Button>
                          </Link>
                        )}

                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => alert(`Assigning ${issue.issueType}`)}
                          style={{ marginLeft: '10px' }}
                        >
                          Assign
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Render cards for mobile screens */}
          {isMobile && sortByStatus(groupedIssues[group]).map((issue, index) => (
            <Box
              className={`card-container ${issue.isOpen ? 'open' : ''}`}
              key={issue.id}
              onClick={() => toggleCardContent(group, index)}
            >
              <Box className="card-header">
                <Typography className="card-title">{issue.issueType}</Typography>
                <Typography>{issue.createdAt ? format(issue.createdAt.toDate(), 'yyyy-MM-dd') : 'No Date'}</Typography>
              </Box>
              <Box className="card-content">
                {groupBy === 'status' && <Typography variant="body2">Property: {issue.property}</Typography>}
                <Typography variant="body2">Description: {issue.description}</Typography>
                <Typography variant="body2">Location: {issue.location}</Typography>
                <Box mt={1}>
                  {issue.fileUrl ? (
                    <>
                      {issue.fileUrl.includes('.mp4') || issue.fileUrl.includes('.webm') ? (
                        <video width="150" controls>
                          <source src={issue.fileUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img src={issue.fileUrl} alt="Uploaded media" width="150" />
                      )}
                      <Box mt={1}>
                        <Button variant="outlined" href={issue.fileUrl} target="_blank" download>
                          Download
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Typography>No media</Typography>
                  )}
                </Box>
                {groupBy === 'property' && (
                  <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2 }}>
                    {updatingIssueId === issue.id ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Select
                        value={issue.status || 'Reported'}
                        onChange={(e) => handleUpdateStatus(issue.id, e.target.value)}
                      >
                        <MenuItem value="Reported">Reported</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                        <MenuItem value="Resolved">Resolved</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => alert(`Assigning ${issue.issueType}`)}
                  sx={{ mt: 2 }}
                >
                  Assign
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Container>
  );
}

export default Dashboard;

