import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function TrackLocation() {
  const { issueId } = useParams();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapsLoaded, setMapsLoaded] = useState(false); // To track if the Google Maps API is fully loaded

  useEffect(() => {
    const issueRef = doc(db, 'maintenance', issueId);

    const unsubscribe = onSnapshot(issueRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setLocation(data.locationTracking);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [issueId]);

  const handleMapsLoaded = () => {
    setMapsLoaded(true); // Set state to true when maps are fully loaded
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!location) {
    return (
      <Box textAlign="center" mt={5}>
        <Typography variant="h5">No location data available</Typography>
      </Box>
    );
  }

  // Path to the custom maintenance man icon
  const iconUrl = "/maintenance_man_icon.png"; 

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={handleMapsLoaded}>
      {mapsLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={{ lat: location.lat, lng: location.lng }}
          zoom={15}
        >
          <Marker
            position={{ lat: location.lat, lng: location.lng }}
            icon={{
              url: iconUrl,  // Custom icon URL
              scaledSize: new window.google.maps.Size(40, 40), // Resize the icon (adjust as needed)
              anchor: new window.google.maps.Point(20, 20)  // Adjust anchor point (center the icon)
            }}
          />
        </GoogleMap>
      ) : (
        <CircularProgress /> // Show loading spinner until the maps are fully loaded
      )}
      <Box mt={2} textAlign="center">
        <Typography variant="h6">Last updated: {new Date(location.lastUpdated.toDate()).toLocaleString()}</Typography>
      </Box>
    </LoadScript>
  );
}

export default TrackLocation;
