import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function NavBar() {
  const { currentUser, logout, userRole } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        LOBI Web Portal
      </Typography>
      <List>
        <ListItem button component={Link} to="/" key="home">
          <ListItemText primary="Home" />
        </ListItem>

        {/* Admin Role */}
        {currentUser && userRole === 'admin' && (
          <>
            <ListItem button component={Link} to="/licensees" key="admin-licensees">
              <ListItemText primary="Licensees" />
            </ListItem>
            <ListItem button component={Link} to="/add-licensee" key="admin-add-licensee">
              <ListItemText primary="Add Licensee" />
            </ListItem>
            <ListItem button component={Link} to="/dashboard" key="admin-dashboard">
              <ListItemText primary="Maintenance" />
            </ListItem>
            <ListItem button component={Link} to="/generate-documents" key="admin-generate-documents">
             <ListItemText primary="Generate Documents" />
            </ListItem>
            <ListItem button component={Link} to="/moving-out-list" key="admin-moving-out-list">
              <ListItemText primary="Moving Out List" />
            </ListItem>

            <ListItem button component={Link} to="/admin-rent-management" key="admin-rent-management">
              <ListItemText primary="Admin Rent Management" />
            </ListItem>
          </>
        )}

        {/* Other roles */}
        {currentUser && userRole === 'viewings' && (
          <>
            <ListItem button component={Link} to="/licensees" key="viewings-licensees">
              <ListItemText primary="Licensees" />
            </ListItem>
            <ListItem button component={Link} to="/add-licensee" key="viewings-add-licensee">
              <ListItemText primary="Add Licensee" />
            </ListItem>

           <ListItem button component={Link} to="/generate-documents" key="generate-documents">
             <ListItemText primary="Generate Documents" />
            </ListItem>
          </>
        )}

        {currentUser && userRole === 'maintenance' && (
          <ListItem button component={Link} to="/dashboard" key="maintenance-dashboard">
            <ListItemText primary="Maintenance" />
          </ListItem>
        )}

        {currentUser && userRole === 'user' && (
          <ListItem button component={Link} to="/licensee-services" key="user-licensee-services">
            <ListItemText primary="User Services" />
          </ListItem>
        )}

        {currentUser && (
          <ListItem button onClick={handleLogout} key="logout">
            <ListItemText primary="Logout" />
          </ListItem>
        )}

        {!currentUser && (
          <>
            <ListItem button component={Link} to="/login" key="login">
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem button component={Link} to="/register" key="register">
              <ListItemText primary="Register" />
            </ListItem>
            <ListItem button component={Link} to="/profile" key="profile">
              <ListItemText primary="Profile" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', gap: 2 }}>
            <Button color="inherit" component={Link} to="/">
              Home
            </Button>
            {currentUser && userRole === 'admin' && (
              <>
                <Button color="inherit" component={Link} to="/licensees">
                  Licensees
                </Button>
                <Button color="inherit" component={Link} to="/add-licensee">
                  Add Licensee
                </Button>
                <Button color="inherit" component={Link} to="/dashboard">
                  Maintenance
                </Button>
                <Button color="inherit" component={Link} to="/generate-documents">
                  Generate Documents
                </Button>
                <Button color="inherit" component={Link} to="/moving-out-list">
                  Moving Out List
                </Button>
                <Button color="inherit" component={Link} to="/admin-rent-management">
                  Admin Rent Management
                </Button>
              </>
            )}
            {currentUser && userRole === 'god' && (
              <>
                <Button color="inherit" component={Link} to="/licensees">
                  Licensees
                </Button>
                <Button color="inherit" component={Link} to="/add-licensee">
                  Add Licensee
                </Button>
                <Button color="inherit" component={Link} to="/dashboard">
                  Maintenance
                </Button>
                <Button color="inherit" component={Link} to="/generate-documents">
                  Generate Documents
                </Button>
                <Button color="inherit" component={Link} to="/moving-out-list">
                  Moving Out List
                </Button>
                <Button color="inherit" component={Link} to="/admin-rent-management">
                  Admin Rent Management
                </Button>
                <Button color="inherit" component={Link} to="/tracking-profit">
                  Tracking Profit
                </Button>
              </>
            )}
            {currentUser && userRole === 'viewings' && (
              <>
                <Button color="inherit" component={Link} to="/licensees">
                  Licensees
                </Button>
                <Button color="inherit" component={Link} to="/add-licensee">
                  Add Licensee
                </Button>
                <Button color="inherit" component={Link} to="/generate-documents">
                  Generate Documents
                </Button>
              </>
            )}
            {currentUser && userRole === 'maintenance' && (
              <Button color="inherit" component={Link} to="/dashboard">
                Maintenance
              </Button>
            )}
            {currentUser && (
              <>
                <Button color="inherit" component={Link} to="/profile">
                  Profile
                </Button>
                <Button color="inherit" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            )}
            {!currentUser && (
              <>
                <Button color="inherit" component={Link} to="/login">
                  Login
                </Button>
                <Button color="inherit" component={Link} to="/register">
                  Register
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}

export default NavBar;