import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext'; // Custom hook for auth context
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'; // Firebase Auth methods
import { db } from '../firebase'; // Firebase Firestore instance
import { doc, getDoc } from 'firebase/firestore'; // Firestore methods
import { Container, TextField, Button, Typography, Box, Alert, Paper } from '@mui/material';

function Profile() {
  const { currentUser } = useAuth(); // Get current user from AuthContext
  const [userData, setUserData] = useState(null);
  const [licenseeData, setLicenseeData] = useState(null);
  
  // State for password fields and error/confirmation messages
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          // Fetch user details from Firestore
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userInfo = userDoc.data();
            setUserData(userInfo);

            // Fetch licensee data using referenceCode
            if (userInfo.referenceCode) {
              const licenseeDocRef = doc(db, 'licensees', userInfo.referenceCode);
              const licenseeDoc = await getDoc(licenseeDocRef);

              if (licenseeDoc.exists()) {
                setLicenseeData(licenseeDoc.data());
              } else {
                setError('No licensee data found for this user.');
              }
            }
          }
        } catch (err) {
          console.error('Error fetching user or licensee data:', err);
          setError('Failed to load user data.');
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handlePasswordUpdate = async () => {
    setError('');
    setMessage('');
    
    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match.');
      return;
    }

    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    // Reauthenticate the user with their current password
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    try {
      await reauthenticateWithCredential(user, credential);

      // If reauthentication successful, update the password
      await updatePassword(user, newPassword);
      setMessage('Password updated successfully!');
    } catch (err) {
      console.error('Failed to update password:', err);
      setError('Failed to update password. Please make sure your current password is correct.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Your Profile
          </Typography>

          {error && <Alert severity="error">{error}</Alert>}
          {message && <Alert severity="success">{message}</Alert>}

          {/* Display User Info */}
          {userData && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="body1"><strong>Name:</strong> {userData.fullName.split(' ')[0]}</Typography>
              <Typography variant="body1"><strong>Surname:</strong> {userData.fullName.split(' ')[1]}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {currentUser.email}</Typography>
              <Typography variant="body1"><strong>Reference Code:</strong> {userData.referenceCode}</Typography>
            </Box>
          )}

          {/* Display Licensee Info */}
          {licenseeData && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="body1"><strong>Rent Date:</strong> {licenseeData['RENT DATE']}</Typography>
              <Typography variant="body1"><strong>Address:</strong> {licenseeData.ADDRESS}</Typography>
              <Typography variant="body1"><strong>Rent:</strong> {licenseeData.RENT}</Typography>
            </Box>
          )}

          {/* Password Update */}
          <Box>
            <Typography variant="h6">Change Password</Typography>

            <TextField
              fullWidth
              label="Current Password"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              sx={{ mb: 2 }}
              required
            />

            <TextField
              fullWidth
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{ mb: 2 }}
              required
            />

            <TextField
              fullWidth
              label="Confirm New Password"
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              sx={{ mb: 2 }}
              required
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handlePasswordUpdate}
              disabled={loading}
              fullWidth
            >
              {loading ? 'Updating...' : 'Update Password'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Profile;
