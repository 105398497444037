import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import getStorage
import { getAuth } from "firebase/auth"; // Import getAuth

const firebaseConfig = {
  apiKey: "AIzaSyBVGOAq-32JH6Tnsd1MRxMxZuyXeRFP4aM",
  authDomain: "lobi-portal.firebaseapp.com",
  projectId: "lobi-portal",
  storageBucket: "lobi-portal.appspot.com",
  messagingSenderId: "606476710887",
  appId: "1:606476710887:web:0e67ee5e9c7cbcea648342",
  measurementId: "G-PBPQRS6YTN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);
const auth = getAuth(app);

// Initialize Firebase Storage
const storage = getStorage(app); // Initialize Firebase Storage

export { db, storage, auth }; // Export both Firestore and Storage
