import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Tooltip, Container, TextField, Box, Button
} from '@mui/material';
import { formatDate } from '../utils/utils';
import './MovingOutList.css';  // Import the CSS file

function MovingOutList() {
  const [notices, setNotices] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredNotices, setFilteredNotices] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc'); // State for sort order
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Determine if on mobile

  useEffect(() => {
    const fetchNotices = async () => {
      const noticesSnapshot = await getDocs(collection(db, 'notices'));
      const noticesList = noticesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNotices(noticesList);
    };

    fetchNotices();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const filteredList = notices.filter(notice => 
      notice.name.toLowerCase().includes(search.toLowerCase()) ||
      notice.referenceCode.toLowerCase().includes(search.toLowerCase()) ||
      notice.reason.toLowerCase().includes(search.toLowerCase())
    );

    // Sort notices by move-out date
    filteredList.sort((a, b) => {
      const dateA = new Date(a.noticeDate);
      const dateB = new Date(b.noticeDate);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setFilteredNotices(filteredList);
  }, [search, notices, sortOrder]);

  // Function to toggle sort order
  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const toggleCardContent = (index) => {
    setNotices(notices.map((notice, i) => (
      i === index ? { ...notice, isOpen: !notice.isOpen } : notice
    )));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Moving Out List
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Button variant="contained" onClick={toggleSortOrder}>
        Sort by Move-Out Date ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
      </Button>
      <Box mt={4}>
        {/* Render table for larger screens */}
        {!isMobile && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '20%' }}>Name</TableCell>
                  <TableCell style={{ width: '30%' }}>Reference Code</TableCell>
                  <TableCell style={{ width: '20%' }}>
                    Move-Out Date
                  </TableCell>
                  <TableCell style={{ width: '30%' }}>Reason for Leaving</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredNotices.map(notice => (
                  <TableRow key={notice.id}>
                    <TableCell>{notice.name}</TableCell>
                    <TableCell>{notice.referenceCode}</TableCell>
                    <TableCell>{formatDate(notice.noticeDate)}</TableCell>
                    <TableCell>
                      <Tooltip title={notice.reason}>
                        <span style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block'
                        }}>
                          {notice.reason}
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Render cards for mobile screens */}
        {isMobile && filteredNotices.map((notice, index) => (
          <Box
            className={`card-container ${notice.isOpen ? 'open' : ''}`}
            key={notice.id}
            onClick={() => toggleCardContent(index)}
          >
            <Box className="card-header">
              <Typography className="card-title">{notice.name}</Typography>
              <Typography>{notice.referenceCode}</Typography>
            </Box>
            <Box className="card-content">
              <Typography variant="body2">Move-Out Date: {formatDate(notice.noticeDate)}</Typography>
              <Typography variant="body2">Reason: {notice.reason}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
}

export default MovingOutList;
