import React from 'react';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

function NoticeConfirmation({ formData, onConfirm, onBack }) {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const handleConfirmClick = async () => {
        await onConfirm();  // First, handle the actual submission logic

        // Step 1: Query the licensees collection for the matching reference code
        if (currentUser) {
            const licenseeQuery = query(
                collection(db, 'licensees'),
                where('REFERENCE CODE', '==', formData.referenceCode)
            );
            try {
                const licenseeSnapshot = await getDocs(licenseeQuery);
                if (!licenseeSnapshot.empty) {
                    const licenseeDoc = licenseeSnapshot.docs[0].ref;  // Get the first matching document reference
                    
                    // Step 2: Update the licensee document with the "Moving Out" field
                    await updateDoc(licenseeDoc, {
                        "MOVING OUT": formData.noticeDate  // Add the "Moving Out" field with the chosen date
                    });

                    console.log("Licensee document updated with Moving Out date");
                } else {
                    console.log('No matching licensee found with this reference code');
                }
            } catch (error) {
                console.error('Error updating licensee document:', error);
            }
        }

        // Step 3: Navigate to success page
        navigate('/success');
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
                <Typography variant="h1" gutterBottom>
                    Confirm Notice Submission
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Please review the information below and confirm that it is correct:
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Full Name:</Typography>
                    <Typography variant="body1">{formData.name}</Typography>

                    <Typography variant="h6">Reference Code:</Typography>
                    <Typography variant="body1">{formData.referenceCode}</Typography>

                    <Typography variant="h6">Address:</Typography>
                    <Typography variant="body1">{formData.address}</Typography>

                    <Typography variant="h6">Move-Out Date:</Typography>
                    <Typography variant="body1">{formData.noticeDate}</Typography>

                    <Typography variant="h6">Reason for Leaving:</Typography>
                    <Typography variant="body1">{formData.reason}</Typography>
                </Box>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" color="primary" onClick={handleConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="outlined" onClick={onBack}>
                        Back
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}

export default NoticeConfirmation;
