import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Paper, Box, Button, List, ListItem, ListItemText } from '@mui/material';
import { doc, getDocs, updateDoc, collection, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Firestore database
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom'; // To redirect users
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function Home() {
  const { currentUser, userRole, userStatus } = useAuth(); // Access userRole and userStatus
  const [pendingUsers, setPendingUsers] = useState([]); // State to store pending users
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize navigate for programmatic routing

  // Dummy data for pie charts (replace with actual data)
  const bedData = {
    labels: ['Occupied Beds', 'Empty Beds'],
    datasets: [
      {
        data: [120, 30], // Example values, replace with actual data
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const goalData = {
    labels: ['Achieved', 'Remaining'],
    datasets: [
      {
        data: [75, 25], // Example values, replace with actual data
        backgroundColor: ['#FFCE56', '#FF6384'],
        hoverBackgroundColor: ['#FFCE56', '#FF6384'],
      },
    ],
  };

  // Redirect normal users to the LicenseeServices page
  useEffect(() => {
    if (userRole === 'user') {
      navigate('/licensee-services'); // Change to your route path
    }
  }, [userRole, navigate]);

  // Fetch pending users when component mounts for staff roles
  useEffect(() => {
    if (userRole === 'admin' || userRole === 'viewings') {
      const fetchPendingUsers = async () => {
        setLoading(true);
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('status', '==', 'pending'));
        const querySnapshot = await getDocs(q);
        const pendingUsersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPendingUsers(pendingUsersList);
        setLoading(false);
      };
      fetchPendingUsers();
    }
  }, [userRole]);

  // Function to approve a user
  const approveUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status: 'approved' });
      setPendingUsers(pendingUsers.filter(user => user.id !== userId)); // Remove from list after approval
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  // If the user's status is pending, show a message and hide other content
  if (userStatus === 'pending') {
    return (
      <Container>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4">Your Account is Pending Approval</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Please wait for an administrator to approve your account.
          </Typography>
        </Paper>
      </Container>
    );
  }

  // Return null while redirecting normal users to avoid displaying the page temporarily
  if (userRole === 'user') {
    return null; // Do not render anything while redirecting
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4">Welcome to LOBI Web Portal</Typography>
            {currentUser && (
              <Typography variant="h6" sx={{ mt: 2 }}>
                You are logged in as {currentUser.email}
              </Typography>
            )}

            {/* Staff section: Display Pie Charts */}
            {userRole && (userRole === 'admin' || userRole === 'maintenance' || userRole === 'viewings' || userRole === 'god') && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h5">Staff Dashboard</Typography>
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Bed Availability</Typography>
                    <Pie data={bedData} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Goal Progress</Typography>
                    <Pie data={goalData} />
                  </Grid>
                </Grid>

                {/* Pending Users section for admin and viewings roles */}
                {(userRole === 'admin' || userRole === 'viewings') && (
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h5">Pending Users for Approval</Typography>
                    {loading ? (
                      <Typography>Loading...</Typography>
                    ) : (
                      <List>
                        {pendingUsers.map(user => (
                          <ListItem key={user.id}>
                            <ListItemText primary={user.fullName} secondary={user.email} />
                            <Button 
                              variant="contained" 
                              color="primary" 
                              onClick={() => approveUser(user.id)}
                            >
                              Approve
                            </Button>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
