import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, LinearProgress, FormControl, Autocomplete, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage'; 
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

function ReportDetail() {
  const { category } = useParams();
  const { currentUser } = useAuth();
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [fileUrl, setFileUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [propertys, setPropertys] = useState([]); // List of unique property codes
  const [selectedProperty, setSelectedProperty] = useState(''); // Selected property code
  const [videos, setVideos] = useState([]); // List of instructional videos
  const [loadingVideos, setLoadingVideos] = useState(false); // Loading state for videos

  // Fetch property reference codes from the 'licensees' collection
  useEffect(() => {
    const fetchPropertys = async () => {
      try {
        const licenseeCollection = collection(db, 'licensees');
        const licenseeSnapshot = await getDocs(licenseeCollection);
        const licensees = licenseeSnapshot.docs.map(doc => doc.data());

        // Extract unique property codes by removing trailing digits from reference codes
        const propertyCodes = Array.from(new Set(licensees.map(licensee => {
          return licensee['REFERENCE CODE'].replace(/\d+$/, ''); // Remove trailing digits
        })));

        setPropertys(propertyCodes);
      } catch (err) {
        console.error('Failed to fetch property codes:', err);
      }
    };

    fetchPropertys();
  }, []);

  // Fetch instructional videos based on selected property and category
  const fetchVideos = async () => {
    if (!selectedProperty || !category) return;

    setLoadingVideos(true);
    const videoPath = `videos/${selectedProperty}/${category}`;
    const videoRef = ref(storage, videoPath);
    const videoList = await listAll(videoRef);
    const videoUrls = await Promise.all(videoList.items.map(item => getDownloadURL(item)));
    setVideos(videoUrls);
    setLoadingVideos(false);
  };

  useEffect(() => {
    if (selectedProperty) {
      fetchVideos();
    }
  }, [selectedProperty, category]);

  const handleFileUpload = () => {
    if (file) {
      setUploadStatus('uploading');
      const fileRef = ref(storage, `maintenance/${Date.now()}-${file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Error uploading media:', error);
          setUploadStatus('error');
          setMessage('Error uploading media. Please try again.');
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          setFileUrl(url);
          setUploadStatus('success');
          setMessage('Media uploaded successfully!');
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setMessage('You must be logged in to report an issue.');
      return;
    }

    if (!selectedProperty) {
      setMessage('Please select a property.');
      return;
    }

    try {
      const maintenanceDoc = {
        issueType: category,
        description,
        property: selectedProperty, // Use selected property as both property and location
        location: selectedProperty, // Automatically set location to the selected property
        status: 'Reported',
        createdAt: new Date(),
        userId: currentUser.uid,
        assignee: null,
      };

      // If a file is uploaded, include its URL in the document
      if (fileUrl) {
        maintenanceDoc.fileUrl = fileUrl;
      }

      await addDoc(collection(db, 'maintenance'), maintenanceDoc);

      setMessage('Issue reported successfully!');
      setDescription('');
      setFile(null);
      setUploadProgress(0);
      setFileUrl(null);
      setSelectedProperty(''); // Reset selected property
    } catch (error) {
      console.error('Error reporting the issue:', error);
      setMessage('Failed to report the issue. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadStatus('idle');
    setMessage('');
    setUploadProgress(0);
  };

  return (
    <Container maxWidth="sm">
      {/* Instructional Videos Section */}
      <Typography variant="h5" gutterBottom>
        Instructional Videos for {category}
      </Typography>

      {loadingVideos ? (
        <CircularProgress />
      ) : videos.length > 0 ? (
        <Box>
          {videos.map((videoUrl, index) => (
            <Box key={index} mt={2}>
              <video width="100%" controls>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography>No instructional videos available for this category.</Typography>
      )}

      {/* Form for Reporting Issue */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: 3,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          boxShadow: 3,
          mt: 4, // Margin top to separate form from videos
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Report {category} Issue
        </Typography>

        {/* Property Search and Select using Autocomplete */}
        <FormControl fullWidth margin="normal">
          <Autocomplete
            options={propertys}
            getOptionLabel={(option) => option}
            value={selectedProperty}
            onChange={(event, newValue) => setSelectedProperty(newValue || '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Property"
                placeholder="Type to search property by reference code"
                required
              />
            )}
          />
        </FormControl>

        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={4}
          fullWidth
          margin="normal"
        />

        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Select Photo/Video
          <input
            type="file"
            hidden
            onChange={handleFileChange}
          />
        </Button>

        {file && (
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 2 }}
            onClick={handleFileUpload}
            disabled={uploadStatus === 'uploading' || uploadStatus === 'success'}
          >
            Upload Photo/Video
          </Button>
        )}

        {uploadStatus === 'uploading' && (
          <Box sx={{ mt: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              Uploading... {Math.round(uploadProgress)}%
            </Typography>
          </Box>
        )}

        {uploadStatus === 'success' && (
          <Typography variant="body2" align="center" sx={{ mt: 2, color: 'green' }}>
            Media uploaded successfully!
          </Typography>
        )}

        {uploadStatus === 'error' && (
          <Typography variant="body2" align="center" sx={{ mt: 2, color: 'red' }}>
            Error uploading media. Please try again.
          </Typography>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          // Button is no longer disabled based on media upload status
        >
          Submit
        </Button>

        {message && (
          <Typography variant="body1" align="center" sx={{ mt: 2, color: 'text.primary' }}>
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default ReportDetail;
