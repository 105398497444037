import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Alert, Paper, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { storage, db } from '../firebase';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore'; // Firestore functions for saving temp file
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

const CLOUD_VISION_API_KEY = 'AIzaSyBz3DYmUqD-RdI8vrW37dkcjcDfKbsRKkQ';
const OPENAI_API_KEY = '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';

function Register() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const { signup } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [idFile, setIdFile] = useState(null); // Store the file and temp ID
  const [uploadProgress, setUploadProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  const [tempFileId, setTempFileId] = useState(null); // Store temp file ID

  // Handle registration form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setStatusMessage('Starting registration process...');
    setLoading(true);

    try {
      // Create the user in Firebase Authentication
      const user = await signup(email, password);
      console.log('User created:', user.uid);

      // Store user details in Firestore, including temp file ID for later linking
      const docData = {
        fullName: `${firstName} ${lastName}`,
        email: email,
        referenceCode: referenceCode,
        role: 'user',
        status: 'pending',
        tempFileId: tempFileId, // Save the temp file ID
      };

      await setDoc(doc(db, 'users', user.uid), docData);

      setStatusMessage('Registration successful!');
      navigate('/registration-success');
    } catch (err) {
      console.error('Error during registration:', err);
      setError('Failed to create an account. Please try again.');
      setLoading(false);
    }
  };

  // Handle file upload and generate temp file ID
  const handleFileChange = async (e) => {
    setIdFile(e.target.files[0]);
    setStatusMessage('Uploading ID image...');
    console.log('Selected ID image:', e.target.files[0]);

    try {
      // Generate a temporary file ID
      const tempFileId = `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      setTempFileId(tempFileId); // Save temp ID for later linking

      const tempFilePath = `unverified-uploads/${tempFileId}.jpeg`;
      const tempFileRef = storageRef(storage, tempFilePath);

      const uploadTask = uploadBytesResumable(tempFileRef, e.target.files[0]);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          console.log(`Upload progress: ${progress}%`);
          setStatusMessage(`Uploading ID image: ${progress}% completed.`);
        },
        (error) => {
          console.error('Image upload error:', error);
          setStatusMessage('Error uploading ID image.');
        },
        async () => {
          const publicImageUrl = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('Image uploaded successfully:', publicImageUrl);
          setStatusMessage('ID image uploaded successfully.');
          
          // After the image is uploaded, continue with parsing the text
          await processImageWithVisionAndGPT(publicImageUrl);
        }
      );
    } catch (err) {
      console.error('Error during image handling:', err);
      setStatusMessage('Error during image upload process.');
    }
  };

  // Process the image with Google Cloud Vision and OpenAI GPT
  // Process the image with Google Cloud Vision and OpenAI GPT
const processImageWithVisionAndGPT = async (imageUrl) => {
  // Call Google Cloud Vision API to get the extracted text
  const extractedText = await callGoogleVisionAPI(imageUrl);

  if (extractedText) {
    console.log('Extracted Text from Google Vision API:', extractedText);
    setStatusMessage('Text extracted from ID. Analyzing...');

    // Pass the extracted text to OpenAI GPT to find the first name and surname
    const nameData = await callOpenAIForName(extractedText);

    if (nameData) {
      console.log('Parsed Name and Surname from GPT:', nameData);
      setStatusMessage('Name and Surname successfully extracted from text.');

      // Set the extracted names to the form fields
      setFirstName(nameData.firstName);
      setLastName(nameData.lastName);
    } else {
      setStatusMessage('Failed to extract Name and Surname from text.');
    }
  } else {
    setStatusMessage('Failed to extract text from ID.');
  }
};


  // Google Cloud Vision API call
  const callGoogleVisionAPI = async (imageUrl) => {
    try {
      const requestPayload = {
        requests: [
          {
            image: {
              source: {
                imageUri: imageUrl,
              },
            },
            features: [
              {
                type: 'TEXT_DETECTION',
              },
            ],
          },
        ],
      };

      console.log('Sending request to Google Vision API with payload:', requestPayload);

      const response = await axios.post(
        `https://vision.googleapis.com/v1/images:annotate?key=${CLOUD_VISION_API_KEY}`,
        requestPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const annotations = response.data.responses[0].textAnnotations;
      if (annotations && annotations.length > 0) {
        const fullText = annotations[0].description;
        return fullText;
      } else {
        console.error('No annotations found in the response');
        return null;
      }
    } catch (error) {
      console.error('Error calling Google Vision API:', error.response?.data || error.message);
      return null;
    }
  };

  // OpenAI GPT API call to extract Name and Surname
  const callOpenAIForName = async (extractedText) => {
    try {
      const gptResponse = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4',
          messages: [
            {
              role: 'system',
              content: 'You are an assistant that extracts "First Name" and "Surname" from text and provides them in a structured format.',
            },
            {
              role: 'user',
              content: `Here is some extracted text from an ID card: "${extractedText}". Can you find the "First Name" and "Surname" from this text and provide it in this format: [First Name: NAME] [Surname: SURNAME]?`,
            },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${OPENAI_API_KEY}`,
          },
        }
      );

      const gptResult = gptResponse.data.choices[0].message.content;
      const firstNameMatch = gptResult.match(/\[First Name:\s*([^\]]+)\]/);
      const lastNameMatch = gptResult.match(/\[Surname:\s*([^\]]+)\]/);

      return {
        firstName: firstNameMatch ? firstNameMatch[1].trim() : '',
        lastName: lastNameMatch ? lastNameMatch[1].trim() : '',
      };
    } catch (error) {
      console.error('Error calling OpenAI GPT:', error.response?.data || error.message);
      return null;
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Register
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          {statusMessage && <Alert severity="info">{statusMessage}</Alert>}
          <Button variant="contained" component="label" fullWidth sx={{ mt: 2, mb: 2 }}>
              Upload Government ID (optional)
              <input type="file" hidden onChange={handleFileChange} />
            </Button>

            {uploadProgress > 0 && uploadProgress < 100 && (
              <LinearProgress variant="determinate" value={uploadProgress} />
            )}

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="First Name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Last Name"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Reference Code"
              type="text"
              value={referenceCode}
              onChange={(e) => setReferenceCode(e.target.value)}
              required
            />

         
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? 'Registering...' : 'Sign Up'} 
            </Button>
          </form>
        </Box>
      </Paper>
    </Container>
  );
}

export default Register;
