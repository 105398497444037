import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, limit, startAfter, orderBy, where, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import {
  Container, Typography, Box, CircularProgress, Paper, Button, TextField, Dialog, DialogActions, DialogContent,
  DialogTitle, FormControl, InputLabel, Select, MenuItem, IconButton
} from '@mui/material';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { formatDate } from '../utils/utils';
import { FixedSizeList as List } from 'react-window';

const ScrollableContainer = styled(Paper)`
  overflow: hidden;
  height: 600px;
  width: 100%;
  max-width: 100%;
`;

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 120px 120px 80px 80px 120px 80px 80px 80px 80px;
  background-color: #f5f5f5;
  padding: 8px;
  font-weight: normal;
  font-size: 14px;
`;

const RowGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 120px 120px 80px 80px 120px 80px 80px 80px 80px;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  align-items: center;
  font-size: 14px;
`;

const TextCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 8px;
`;

const ButtonCell = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
`;

const SmallButton = styled(Button)`
  font-size: 10px;
  padding: 4px 6px;
`;

const LicenseeList = () => {
  const [licensees, setLicensees] = useState([]);
  const [notices, setNotices] = useState([]);
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [allLoaded, setAllLoaded] = useState(false);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterMovingOut, setFilterMovingOut] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedLicensee, setSelectedLicensee] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [editedLicensee, setEditedLicensee] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [licenseeToDelete, setLicenseeToDelete] = useState(null);

  // Fetch initial data
  const fetchInitialData = async () => {
    setLoading(true);
    const licenseeCollection = collection(db, 'licensees');
    const firstQuery = query(licenseeCollection, orderBy('REFERENCE CODE'), limit(30));
    const licenseeSnapshot = await getDocs(firstQuery);
    const firstLicensees = licenseeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    setLicensees(firstLicensees);
    setLastVisible(licenseeSnapshot.docs[licenseeSnapshot.docs.length - 1]);

    // Load notices
    const noticesCollection = collection(db, 'notices');
    const noticesSnapshot = await getDocs(noticesCollection);
    const noticesList = noticesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setNotices(noticesList);

    const propertyCodesSet = new Set(firstLicensees.map(licensee => extractPropertyCode(licensee['REFERENCE CODE'])));
    setPropertyCodes(Array.from(propertyCodesSet));

    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Function to fetch more licensees
  const loadMoreLicensees = useCallback(async () => {
    if (loadingMore || allLoaded) return;
    setLoadingMore(true);

    const licenseeCollection = collection(db, 'licensees');
    const nextQuery = query(licenseeCollection, orderBy('REFERENCE CODE'), startAfter(lastVisible), limit(30));
    const licenseeSnapshot = await getDocs(nextQuery);
    const nextLicensees = licenseeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    if (nextLicensees.length > 0) {
      setLicensees(prev => [...prev, ...nextLicensees]);
      setLastVisible(licenseeSnapshot.docs[licenseeSnapshot.docs.length - 1]);
    } else {
      setAllLoaded(true);
    }

    setLoadingMore(false);
  }, [loadingMore, lastVisible, allLoaded]);

  // Handle detecting when the user is near the bottom of the list
  const handleItemsRendered = useCallback(({ visibleStopIndex }) => {
    if (visibleStopIndex >= licensees.length - 5) {
      loadMoreLicensees();
    }
  }, [licensees, loadMoreLicensees]);

  const extractPropertyCode = (referenceCode) => {
    const match = referenceCode.match(/^(\d+[A-Za-z]+)/);
    return match ? match[1] : referenceCode;
  };

  const getMovingOutStatus = (referenceCode) => {
    const matchingNotice = notices.find(notice => notice.referenceCode === referenceCode);
    return matchingNotice ? formatDate(matchingNotice.noticeDate) : 'N/A';
  };

  // Handle Details, Edit, and Delete modals
  const handleDetailsOpen = (licensee) => {
    setSelectedLicensee(licensee);
    setDetailsOpen(true);
  };

  const handleEditOpen = (licensee) => {
    setEditedLicensee(licensee);
    setEditOpen(true);
  };

  const handleDeleteOpen = (licensee) => {
    setLicenseeToDelete(licensee);
    setDeleteOpen(true);
  };

  const handleDeleteSubmit = async () => {
    if (licenseeToDelete) {
      const licenseeRef = doc(db, 'licensees', licenseeToDelete.id);
      await deleteDoc(licenseeRef);
      setDeleteOpen(false);
      window.location.reload();
    }
  };

  const handleEditSubmit = async () => {
    if (editedLicensee) {
      const licenseeRef = doc(db, 'licensees', editedLicensee.id);
      await updateDoc(licenseeRef, editedLicensee);
      setEditOpen(false);
      window.location.reload();
    }
  };

  // Row rendering for react-window
  const RenderedRow = ({ index, style, data }) => {
    const licensee = data[index];
    return (
      <RowGrid style={style} key={licensee.id}>
        <TextCell>{licensee['REFERENCE CODE'] || 'N/A'}</TextCell>
        <TextCell>{licensee.NAME || 'N/A'}</TextCell>
        <TextCell>{licensee.SURNAME || 'N/A'}</TextCell>
        <TextCell>{licensee.DEPOSIT || 'N/A'}</TextCell>
        <TextCell>{licensee.RENT || 'N/A'}</TextCell>
        <TextCell>{licensee['PHONE NUMBER'] || 'N/A'}</TextCell>
        <TextCell>{getMovingOutStatus(licensee['REFERENCE CODE'])}</TextCell>
        <ButtonCell>
          <SmallButton variant="outlined" size="small" onClick={() => handleDetailsOpen(licensee)}>
            Details
          </SmallButton>
        </ButtonCell>
        <ButtonCell>
          <SmallButton variant="outlined" size="small" onClick={() => handleEditOpen(licensee)}>
            Edit
          </SmallButton>
        </ButtonCell>
        <ButtonCell>
          <SmallButton variant="outlined" size="small" color="error" onClick={() => handleDeleteOpen(licensee)}>
            Delete
          </SmallButton>
        </ButtonCell>
      </RowGrid>
    );
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={{ width: '100%', maxWidth: '100%', overflowX: 'hidden' }}>
      <Box sx={{ width: '100%', maxWidth: '100%' }}>
        <Typography variant="h4" component="h4" gutterBottom>
          Licensee List
        </Typography>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Property</InputLabel>
          <Select
            value={selectedPropertyCode}
            onChange={(event) => setSelectedPropertyCode(event.target.value)}
          >
            <MenuItem value="">
              <em>All Properties</em>
            </MenuItem>
            {propertyCodes.map(code => (
              <MenuItem key={code} value={code}>
                {code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            sx={{ flexGrow: 1, mr: 2 }}
          />
          <Button
            variant={filterMovingOut ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setFilterMovingOut(prev => !prev)}
            sx={{
              minWidth: '180px',
              padding: '6px 12px',
              textTransform: 'none',
            }}
          >
            {filterMovingOut ? 'Show All' : 'Show Moving'}
          </Button>
        </Box>

        <ScrollableContainer>
          <HeaderGrid>
            <div>Reference Code</div>
            <div>Name</div>
            <div>Surname</div>
            <div>Deposit</div>
            <div>Rent</div>
            <div>Phone Number</div>
            <div>Moving Out Date</div>
            <div>Details</div>
            <div>Edit</div>
            <div>Delete</div>
          </HeaderGrid>
          <List
            height={600}
            itemCount={licensees.length}
            itemSize={50}
            width="100%"
            itemData={licensees}
            onItemsRendered={handleItemsRendered}
          >
            {RenderedRow}
          </List>
        </ScrollableContainer>

        {loadingMore && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      {/* Details Dialog */}
      <Dialog open={detailsOpen} onClose={() => setDetailsOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Licensee Details</DialogTitle>
        <DialogContent>
          {selectedLicensee && (
            <Box>
              <Typography variant="h6">Reference Code: {selectedLicensee['REFERENCE CODE']}</Typography>
              <Typography>Name: {selectedLicensee.NAME}</Typography>
              <Typography>Surname: {selectedLicensee.SURNAME}</Typography>
              <Typography>Rent: {selectedLicensee.RENT}</Typography>
              <Typography>Deposit: {selectedLicensee.DEPOSIT}</Typography>
              <Typography>Phone Number: {selectedLicensee['PHONE NUMBER']}</Typography>
              <Typography>Moved In: {formatDate(selectedLicensee['MOVED IN'])}</Typography>
              <Typography>Email: {selectedLicensee.EMAIL}</Typography>
              <Typography>Eircode: {selectedLicensee.EIRCODE}</Typography>
              <Typography>Address: {selectedLicensee.ADDRESS}</Typography>
              <Typography>Moving Out: {getMovingOutStatus(selectedLicensee['REFERENCE CODE']) || 'N/A'}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailsOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={editOpen} onClose={() => setEditOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Licensee</DialogTitle>
        <DialogContent>
          {editedLicensee && (
            <Box component="form" noValidate>
              <TextField
                margin="dense"
                label="Reference Code"
                fullWidth
                value={editedLicensee['REFERENCE CODE']}
                name="REFERENCE CODE"
                onChange={(e) => setEditedLicensee(prev => ({ ...prev, 'REFERENCE CODE': e.target.value }))}
              />
              <TextField
                margin="dense"
                label="Name"
                fullWidth
                value={editedLicensee.NAME}
                name="NAME"
                onChange={(e) => setEditedLicensee(prev => ({ ...prev, NAME: e.target.value }))}
              />
              <TextField
                margin="dense"
                label="Surname"
                fullWidth
                value={editedLicensee.SURNAME}
                name="SURNAME"
                onChange={(e) => setEditedLicensee(prev => ({ ...prev, SURNAME: e.target.value }))}
              />
              <TextField
                margin="dense"
                label="Deposit"
                fullWidth
                value={editedLicensee.DEPOSIT}
                name="DEPOSIT"
                onChange={(e) => setEditedLicensee(prev => ({ ...prev, DEPOSIT: e.target.value }))}
              />
              <TextField
                margin="dense"
                label="Rent"
                fullWidth
                value={editedLicensee.RENT}
                name="RENT"
                onChange={(e) => setEditedLicensee(prev => ({ ...prev, RENT: e.target.value }))}
              />
              <TextField
                margin="dense"
                label="Phone Number"
                fullWidth
                value={editedLicensee['PHONE NUMBER']}
                name="PHONE NUMBER"
                onChange={(e) => setEditedLicensee(prev => ({ ...prev, 'PHONE NUMBER': e.target.value }))}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Delete Licensee</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this licensee?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSubmit} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LicenseeList;
