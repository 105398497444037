import React, { useState, useEffect } from 'react';
import { collection, addDoc, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { Container, TextField, Button, Typography, Paper, Box } from '@mui/material';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import NoticeConfirmation from './NoticeConfirmation';  
import { useNavigate } from 'react-router-dom';

function GiveNotice() {
    const { currentUser } = useAuth();
    const [formData, setFormData] = useState({
        name: '',
        referenceCode: '',
        address: '',
        reason: '',
        noticeDate: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    // Calculate the minimum date (28 days from today)
    const getMinNoticeDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 28);
        return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };

    useEffect(() => {
        if (currentUser) {
            const fetchUserAndLicenseeData = async () => {
                try {
                    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        const referenceCode = userData.referenceCode;

                        const licenseeQuery = query(collection(db, 'licensees'), where('REFERENCE CODE', '==', referenceCode));
                        const licenseeSnapshot = await getDocs(licenseeQuery);
                        
                        if (!licenseeSnapshot.empty) {
                            const licenseeData = licenseeSnapshot.docs[0].data();
                            setFormData({
                                name: `${licenseeData.NAME || ''} ${licenseeData.SURNAME || ''}`.trim(),
                                referenceCode: licenseeData['REFERENCE CODE'] || 'N/A',
                                address: licenseeData.ADDRESS || 'N/A',
                                reason: '',
                                noticeDate: '',
                            });
                        } else {
                            setMessage('No licensee found with this reference code.');
                        }
                    } else {
                        setMessage('User data not found.');
                    }
                } catch (error) {
                    console.error('Error fetching user or licensee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchUserAndLicenseeData();
        } else {
            setLoading(false);
        }
    }, [currentUser]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
    };

    const handleConfirm = async () => {
        try {
            await addDoc(collection(db, 'notices'), {
                ...formData,
                userId: currentUser.uid,
                createdAt: new Date(),
            });

            setMessage('Notice submitted successfully!');
            navigate('/thank-you');
        } catch (error) {
            console.error('Error submitting notice:', error);
            setMessage('Failed to submit notice. Please try again.');
        }
    };

    const handleBack = () => {
        setSubmitted(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (submitted) {
        return <NoticeConfirmation formData={formData} onConfirm={handleConfirm} onBack={handleBack} />;
    }

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
                <Typography variant="h1" gutterBottom>
                    Give Notice
                </Typography>
                <Typography variant="body1" gutterBottom>
                    If you wish to give notice for moving out, please complete the form below.
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    {/* Full Name (read-only) */}
                    <TextField
                        fullWidth
                        label="Full Name"
                        variant="outlined"
                        name="name"
                        value={formData.name}
                        InputProps={{
                            readOnly: true,
                        }}
                        margin="normal"
                    />
                    {/* Reference Code (read-only) */}
                    <TextField
                        fullWidth
                        label="Reference Code"
                        variant="outlined"
                        name="referenceCode"
                        value={formData.referenceCode}
                        InputProps={{
                            readOnly: true,
                        }}
                        margin="normal"
                    />
                    {/* Address (read-only) */}
                    <TextField
                        fullWidth
                        label="Address"
                        variant="outlined"
                        name="address"
                        value={formData.address}
                        InputProps={{
                            readOnly: true,
                        }}
                        margin="normal"
                        multiline
                        rows={2}
                    />
                    {/* Move-Out Date (editable) */}
                    <TextField
                        fullWidth
                        label="Move-Out Date"
                        variant="outlined"
                        type="date"
                        name="noticeDate"
                        value={formData.noticeDate}
                        onChange={handleChange}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true, // Ensures the label stays above the input
                        }}
                        required
                        inputProps={{
                            min: getMinNoticeDate(), // Minimum selectable date is 28 days from today
                        }}
                    />
                    {/* Reason for Leaving (editable) */}
                    <TextField
                        fullWidth
                        label="Reason for Leaving"
                        variant="outlined"
                        name="reason"
                        value={formData.reason}
                        onChange={handleChange}
                        margin="normal"
                        multiline
                        rows={4}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 3 }}
                    >
                        Submit Notice
                    </Button>
                </Box>
                {message && (
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{ mt: 2 }}
                    >
                        {message}
                    </Typography>
                )}
            </Paper>
        </Container>
    );
}

export default GiveNotice;
